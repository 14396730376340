<template>
  <v-card :min-height="210">
    <v-card-title class="mb-0 pb-1">
      Internet Connection Details
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-container class="mt-0">
        <v-row>
          <v-col
            v-if="!internetConnectionDetails && !isLoading"
            cols="12"
          >
            <h3 class="font-weight-light">
              Connection details not found
            </h3>
          </v-col>
          <v-col
            v-for="detail in details"
            :key="detail.label"
            cols="12"
            md="3"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="internetConnectionDetails"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                {{ detail.label }}
              </h4>
              <h4 class="font-weight-medium">
                {{ detail.value }}
              </h4>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import DetailsTextShimmer from '@/components/partials/shimmers/DetailsTextShimmer.vue'

export default {
  components: {
    'details-text-shimmer': DetailsTextShimmer,
  },
  props: {
    internetConnectionDetails: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    details() {
      const connectionDetails = this.internetConnectionDetails || {}

      // Based on the internet connection type, we can define the required fields
      if (connectionDetails.internet_connection_type === 'Static Ip') {
        return [
          { label: 'Internet Connection Type', value: connectionDetails.internet_connection_type },
          { label: 'Username', value: connectionDetails.username },
          { label: 'IP Address', value: connectionDetails.ip_address },
          { label: 'Subnet', value: connectionDetails.subnet },
          { label: 'Gateway', value: connectionDetails.gateway },
          { label: 'DNS', value: connectionDetails.dns.join(', ') },
        ]
      }
      if (connectionDetails.internet_connection_type === 'PPPOE') {
        return [
          { label: 'Internet Connection Type', value: connectionDetails.internet_connection_type },
          { label: 'Username', value: connectionDetails.username },
          { label: 'Password', value: connectionDetails.password },
        ]
      }
      if (connectionDetails.internet_connection_type === 'Dynamic Ip') {
        return [
          { label: 'Internet Connection Type', value: connectionDetails.internet_connection_type },
          { label: 'Username', value: connectionDetails.username },
          { label: 'MAC Address', value: connectionDetails.mac_address },
        ]
      }

      // In case a new connection type is added later, it will handle gracefully.
      return []
    },
  },
}
</script>
