<template>
  <v-row v-show="customer.customer_internet_plan_update_schedule">
    <v-col cols="12">
      <v-alert
        border="left"
        type="info"
      >
        <p>
          {{ customerName }}'s internet plan is scheduled to be updated to the "{{ newInternetPlanName }}" plan
          <span v-if="updateAfterCurrentPlanEnds">
            after the current plan ends.
          </span>
          <span v-else>
            on {{ scheduledUpdateDate | formatDate }}.
          </span>
        </p>
        <v-btn
          color="error"
          class="ml-2"
          :loading="isCancelingPlanUpdate"
          @click="cancelPlanUpdate"
        >
          <v-icon left>
            {{ icons.cancel }}
          </v-icon>
          Cancel Plan Update
        </v-btn>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiCancel } from '@mdi/js'

export default {
  props: {
    customerName: {
      type: String,
      default: '',
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCancelingPlanUpdate: false,
      icons: {
        cancel: mdiCancel,
      },
    }
  },
  computed: {
    newInternetPlanName() {
      return this.customer.customer_internet_plan_update_schedule ? this.customer.customer_internet_plan_update_schedule.new_internet_plan.name : 'selected'
    },
    scheduledUpdateDate() {
      return this.customer.customer_internet_plan_update_schedule ? this.customer.customer_internet_plan_update_schedule.scheduled_update_date : ''
    },
    updateAfterCurrentPlanEnds() {
      return this.customer.customer_internet_plan_update_schedule ? this.customer.customer_internet_plan_update_schedule.update_after_current_plan_ends : false
    },
  },
  methods: {
    cancelPlanUpdate() {
      this.isCancelingPlanUpdate = true
      axios
        .delete(`/customer-internet-plan-update-schedule/${this.customer.customer_internet_plan_update_schedule.id}`)
        .then(() => {
          this.$emit('planScheduledCanceled')
          this.$toast.success('Plan update cancelled successfully')
        })
        .catch(error => {
          console.error('Error cancelling plan update:', error)
          this.$toast.error(error.response.data.message)
        })
        .finally(() => {
          this.isCancelingPlanUpdate = false
        })
    },
  },
}
</script>
