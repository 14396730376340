<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Exempt {{ customerName }} From internet disconnection</span>
        </v-card-title>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="saveExemptionDate"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <date-time-picker
                    :label="'Exemption Till Date'"
                    :default-date-time="defaultDateTime"
                    :min-date="new Date().toISOString().substr(0, 10)"
                    @close="showDatePicker = false"
                    @change="updateTillDate"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-row
                    no-gutters
                    align="center"
                  >
                    <!-- Checkbox -->
                    <v-col cols="auto">
                      <v-checkbox
                        v-model="form.maintain_billing_date"
                        label="Maintain billing date"
                        class="mt-0"
                        color="primary"
                        hide-details
                      ></v-checkbox>
                    </v-col>

                    <!-- Tooltip -->
                    <v-col cols="auto">
                      <v-tooltip
                        max-width="300"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            color="primary"
                            class="ml-2"
                            v-bind="attrs"
                            style="cursor: pointer;"
                            v-on="on"
                          >
                            {{ icons.mdiInformationOutline }}
                          </v-icon>
                        </template>
                        <span>When selected, this option ensures that the next billing cycle begins on the current plan's expiry date, regardless of when the customer makes the payment. For instance, if the customer's plan is set to expire on {{ currentDateFormatted }}, the next cycle will start on this date, even if the payment is made later. If this option is not selected, the next billing cycle will commence on the date the customer makes the payment.</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import { mdiInformationOutline } from '@mdi/js'
import moment from 'moment'
import DateTimePicker from '../partials/DateTimePicker.vue'

export default {
  components: {
    DateTimePicker,
  },
  props: {
    showDialog: {
      type: Boolean,
    },
    customerName: {
      type: String,
      default: '',
    },
    customerId: {
      type: String,
      default: null,
    },
    disconnectionExemption: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      till_date: '',
      maintain_billing_date: true,
      customer_id: '',
    }),
    icons: {
      mdiInformationOutline,
    },
  }),
  computed: {
    defaultDateTime() {
      const date = this.form.till_date ? this.form.till_date : new Date()

      return new Date(date).toISOString()
    },
    currentDateFormatted() {
      return moment(new Date()).format('Do MMM YYYY')
    },
  },
  watch: {
    disconnectionExemption: {
      handler(newValue) {
      // Check if newValue is an object and contains the required properties
        if (typeof newValue === 'object' && newValue !== null && 'end_time' in newValue && 'customer_id' in newValue) {
          const { end_time, customer_id, maintain_billing_date } = newValue // Destructure the object to get the properties
          this.form.till_date = end_time
          this.form.customer_id = customer_id
          this.form.maintain_billing_date = maintain_billing_date
        }
      },
      immediate: true, // Run the handler immediately upon component creation
    },
    showDialog(newValue) {
      if (newValue) {
        if (typeof this.disconnectionExemption === 'object' && this.disconnectionExemption !== null && 'end_time' in this.disconnectionExemption && 'customer_id' in this.disconnectionExemption) {
          const { end_time, customer_id, maintain_billing_date } = this.disconnectionExemption // Destructure the object to get the properties
          this.form.till_date = end_time
          this.form.customer_id = customer_id
          this.form.maintain_billing_date = maintain_billing_date
        }
      }
    },
  },
  created() {
    this.form.till_date = this.defaultDateTime // Initialize till_date to current date-time on creation
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      if (!this.customerId) {
        this.$refs.form.reset()
        this.form.till_date = ''
        this.maintain_billing_date = true
      }
    },
    updateTillDate(value) {
      if (value) {
        this.form.till_date = moment(value, 'DD/MM/YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss')
      }
    },
    saveExemptionDate() {
      this.form.customer_id = this.customerId
      if (!this.$refs.form.validate()) return

      const tillDate = moment(this.form.till_date, 'YYYY-MM-DDTHH:mm:ss').format('MMMM Do YYYY, hh:mm A')

      const url = this.disconnectionExemption
        ? `disconnection-exemption/${this.disconnectionExemption.id}`
        : 'disconnection-exemption'

      const httpMethod = this.disconnectionExemption ? 'put' : 'post'

      this.form[httpMethod](url)
        .then(response => {
          this.$toast.success(`${this.customerName} will be exempted from disconnection till ${tillDate}`)
          this.$emit('exempted-from-disconnection', { data: response.data.data })
          this.closeDialog()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },

  },
}
</script>
