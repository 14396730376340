<template>
  <v-row justify="center">
    <v-dialog
      v-model="value"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Update Internet Plan for {{ user.name }}</span>
        </v-card-title>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="updateInternetPlan"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="form.new_internet_plan_id"
                    :items="internetPlans"
                    item-text="name"
                    item-value="id"
                    label="Select New Internet Plan"
                    :loading="isInternetPlansLoading"
                    :rules="[
                      v => !!v || 'New Internet Plan is required',
                    ]"
                    :error="form.errors.has('new_internet_plan_id')"
                    :error-messages="form.errors.get('new_internet_plan_id')"
                    outlined
                    @change="fetchInternetPlans"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-label>Select When to Apply the Plan Change</v-label>
                  <v-radio-group
                    v-model="form.when_to_change_plan"
                    :rules="[
                      v => !!v || 'Required',
                    ]"
                    :error="form.errors.has('when_to_change_plan')"
                    :error-messages="form.errors.get('when_to_change_plan')"
                    row
                  >
                    <v-radio
                      label="Now"
                      value="now"
                    ></v-radio>
                    <v-radio
                      label="After Current Plan Ends"
                      value="after_current_plan_ends"
                    ></v-radio>
                    <v-radio
                      label="On Specific Date"
                      value="on_specific_date"
                    ></v-radio>
                  </v-radio-group>
                  <v-expand-transition>
                    <div v-show="form.when_to_change_plan === 'on_specific_date'">
                      <date-time-picker
                        :min-date="new Date().toISOString()"
                        :default-date-time="new Date().toISOString()"
                        :label="'Specific Date'"
                        @change="updateChangeDate"
                      />
                    </div>
                  </v-expand-transition>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import moment from 'moment'
import DateTimePicker from '../partials/DateTimePicker.vue'

export default {
  components: {
    DateTimePicker,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    form: new Form({
      new_internet_plan_id: null,
      when_to_change_plan: 'now',
      specific_date: '',
      customer_id: null,
    }),
    internetPlans: [],
    isInternetPlansLoading: false,
  }),
  watch: {
    // Watch for changes in the user object
    user: {
      deep: true,
      handler(newValue) {
        this.updateFormCustomerId(newValue)
        this.fetchInternetPlans()
      },
    },
  },
  mounted() {
    this.form.specific_date = moment().format('DD/MM/YYYY h:mm A')
  },
  methods: {
    updateFormCustomerId(user) {
      if (user && user.customer && user.customer.id) {
        this.form.customer_id = user.customer.id
      }
    },
    fetchInternetPlans() {
      this.isInternetPlansLoading = true
      axios.get('internet-plans', {
        params: {
          perPage: 'all',
          internet_connection_type: this.user.customer.internet_connection_type,
          stationId: this.user.customer.station_id,
        },
      })
        .then(response => {
          this.internetPlans = response.data.filter(plan => plan.id !== this.user.customer.customer_plan.internet_plan_id)
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
        .finally(() => {
          this.isInternetPlansLoading = false
        })
    },
    closeDialog() {
      this.$emit('close')
    },
    updateChangeDate(value) {
      console.log('🚀 ~ updateChangeDate ~ value:', value)
      this.form.specific_date = value
    },
    updateInternetPlan() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .post('customer-internet-plan-update-schedule')
          .then(() => {
            let successMessage = 'Customer internet plan has been updated successfully.'
            if (this.form.when_to_change_plan === 'after_current_plan_ends') {
              successMessage = 'Customer internet plan will be updated after the current plan ends.'
            } else if (this.form.when_to_change_plan === 'on_specific_date') {
              const formattedDate = this.$options.filters.formatDate(this.form.specific_date)
              successMessage = `Customer internet plan will be updated on ${formattedDate}.`
            }
            this.$toast.success(successMessage)
            this.$emit('close')
            this.$emit('internet-plan-updated')
            this.closeDialog()
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
  },
}
</script>
