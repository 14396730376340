<template>
  <v-row v-show="customer.has_pending_plan_change">
    <v-col cols="12">
      <v-alert
        border="left"
        type="info"
      >
        <p>
          {{ customerName }} is scheduled to transition to the {{ newPlanName }} plan. To complete the activation of the new plan, a payment of Ksh {{ amountToTopUp | formatCurrency }} is needed.
        </p>
        <v-btn
          color="error"
          class="ml-2"
          :loading="isCancelingPlanChange"
          @click="cancelPlanChange"
        >
          <v-icon left>
            {{ icons.cancel }}
          </v-icon>
          Cancel Plan Change
        </v-btn>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiCancel } from '@mdi/js'

export default {
  props: {
    customerName: {
      type: String,
      default: '',
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCancelingPlanChange: false,
      icons: {
        cancel: mdiCancel,
      },
    }
  },
  computed: {
    newPlanName() {
      return this.customer.pending_plan_change?.new_plan?.name || 'the selected'
    },
    amountToTopUp() {
      return this.customer.pending_plan_change?.amount_to_top_up || 0
    },
  },
  methods: {
    cancelPlanChange() {
      this.isCancelingPlanChange = true
      axios
        .post(`/customer-plans/${this.customer.pending_plan_change.id}/cancel`)
        .then(() => {
          this.$emit('planChangeCanceled', this.customer.pending_plan_change.amount_to_top_up)
          this.$toast.success('Plan change cancelled successfully')
        })
        .catch(error => {
          console.error('Error cancelling plan change:', error)
          this.$toast.error(error.response.data.message)
        })
        .finally(() => {
          this.isCancelingPlanChange = false
        })
    },
  },
}

</script>
