<template>
  <v-dialog
    v-model="showDialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Add Installation Fee for {{ user.name }}</span>
      </v-card-title>
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="saveInstallationFee"
      >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.installation_fee"
                  label="Installation Fee"
                  placeholder="Enter installation fee amount"
                  :rules="[v => !!v || 'Installation fee is required', v => /^[0-9]+$/.test(v) || 'Must be numeric']"
                  :error="form.errors.has('installation_fee')"
                  :error-messages="form.errors.get('installation_fee')"
                  hint="This is the amount to be paid for installation fee. Input numbers only, no commas"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :loading="form.busy"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Form from 'vform'
import { mapGetters } from 'vuex'

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      installation_fee: '',
    }),
  }),
  computed: {
    ...mapGetters(['selectedStation']),
  },
  watch: {
    user(value) {
      if (Object.keys(value).length > 0) {
        this.form.installation_fee = ''
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      this.$refs.form.resetValidation()
      this.form.reset()
    },
    saveInstallationFee() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .post(`customer/update-installation-fee/${this.user.customer.id}`, { installation_fee: this.form.installation_fee })
          .then(() => {
            this.$toast.success('Installation fee saved successfully')
            this.$emit('added')
            this.$emit('close')
            this.closeDialog()
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
  },
}
</script>
