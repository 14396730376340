<template>
  <div class="sms-table">
    <v-card class="pb-1">
      <v-card-title
        v-if="forSpecificCustomer"
        class="mb-0 pb-1"
      >
        Sms Details
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col class="col-md-3">
              <refresh-button
                :loading="isSmsRefreshing"
                @click="refreshSms()"
              />
            </v-col>
            <v-col class="col-md-6">
              <table-filter
                :table-name="'sms'"
                :show-sms-status-filter="true"
                :default-date-picker-date="defaultDatePickerDate"
                @filter="applyFilters"
              />
            </v-col>
            <v-spacer />
          </v-row><v-row
            justify="center"
            class="mb-0"
          >
            <v-col class="col-md-6 text-center">
              <search-input
                v-model="search"
                :filters="searchFilters"
                :active-filter="activeSearchFilter"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-expand-transition>
        <v-row
          v-if="selected.length > 0 && can('sms-delete')"
          class="mb-2"
        >
          <v-col class="text-md-right">
            <v-btn
              color="error"
              class="ma-2"
              @click="showConfirmBulkDeleteDialog = true"
            >
              <v-icon
                left
                size="22"
              >
                {{ icons.mdiDelete }}
              </v-icon>
              Delete Selected
            </v-btn>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="sms"
        :loading="isSmsLoading"
        :server-items-length="pagination.totalItems"
        :options.sync="options"
        :footer-props="footerProps"
        show-select
      >
        <template v-slot:item.message="{ item }">
          {{ item.message }}
        </template>
        <template v-slot:item.station="{ item }">
          {{ item.station ? item.station.name : (item.user ? item.user.customer.station.name : 'unknown') }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            text-color="white"
            class="my-2"
            :class="{
              delivered: item.status === 'Delivered',
              sent: item.status === 'Sent',
              failed: item.status === 'Failed' || item.status === 'InvalidPhoneNumber',
              queue: item.status === 'Queue' || item.status === 'UserInBlacklist',
              'insufficient-balance': item.status === 'InsufficientBalance',
            }"
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | formatDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip
            v-if="can('sms-create')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-4
                v-bind="attrs"
                text
                icon
                @click="showResendSmsDialog(item)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiSend }}
                </v-icon>
              </v-btn>
            </template>
            <span>Resend</span>
          </v-tooltip>
          <v-tooltip
            v-if="can('sms-delete')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-4
                v-bind="attrs"
                text
                icon
                @click="showDeleteSmsDialog(item.id)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider />
      <v-dialog
        v-model="showMessageDialog"
        max-width="450px"
      >
        <v-card>
          <v-card-text>
            <p
              style="white-space: pre-line"
              class="pt-4"
            >
              {{ message }}
            </p>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
    <confirm-dialog
      :show-dialog="showConfirmResendDialog"
      :is-agree-button-loading="isConfirmResendDialogButtonLoading"
      :agree-text="'Resend'"
      :message="resendSmsMessage"
      @cancel="showConfirmResendDialog = false"
      @agree="resendSms()"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteSms()"
    />
    <confirm-dialog
      :show-dialog="showConfirmBulkDeleteDialog"
      :message="'Are you sure you want to delete ' + selected.length + ' records?'"
      :is-agree-button-loading="isConfirmBulkDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmBulkDeleteDialog = false"
      @agree="deleteSelectedSms()"
    />
    <send-sms-dialog
      :show-dialog="sendSmsDialog"
      @close="$emit('close')"
      @sent="onSmsSent()"
    />
  </div>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { mdiSend, mdiDelete } from '@mdi/js'
import SendSmsDialog from '@/components/dialogs/SendSmsDialog.vue'
import SearchInput from '@/components/partials/SearchInput.vue'
import TableFilter from '../partials/table-filter/index.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    SendSmsDialog,
    SearchInput,
    TableFilter,
    RefreshButton,
    ConfirmDialog,
  },
  mixins: [hasPermission],
  props: {
    smsUrl: {
      type: String,
      default: null,
    },
    clickableRows: {
      type: Boolean,
      default: true,
    },
    sendSmsDialog: {
      type: Boolean,
      default: false,
    },
    forSpecificCustomer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      footerProps: {
        'items-per-page-options': [10, 25, 50, 100],
        'items-per-page-text': 'Items per page:',
      },
      pagination: {
        totalItems: 0,
      },
      options: {
        sortBy: ['created_at'],
        sortDesc: [true],
      },
      expanded: [],
      selected: [],
      singleExpand: false,
      isSmsLoading: true,
      isSmsRefreshing: false,
      isConfirmResendDialogButtonLoading: false,
      showConfirmDeleteDialog: false,
      showConfirmBulkDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      isConfirmBulkDeleteDialogButtonLoading: false,
      showMessageDialog: false,
      showConfirmResendDialog: false,
      sms: [],
      smsCost: '',
      message: '',
      filters: {
        dates: {
          fromDate: '',
          toDate: '',
        },
        smsStatus: {
          delivered: false,
          sent: false,
          failed: false,
        },
      },
      activeSearchFilter: 'users.name',
      searchFilters: [
        {
          text: 'Name',
          value: 'users.name',
        },
        {
          text: 'Phone number',
          value: 'phone',
        },
        {
          text: 'Status',
          value: 'status',
        },
      ],
      icons: {
        mdiSend,
        mdiDelete,
      },
      defaultDatePickerDate: '',
      toResendCustomerName: '',
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    refreshTable() {
      return `${this.smsUrl}|${this.search}`
    },
    headers() {
      const headers = [
        {
          text: 'Name',
          value: 'user.name',
        },
        {
          text: 'Phone number',
          value: 'phone',
          sortable: false,
        },
        {
          text: 'Message',
          value: 'message',
          width: '40%',
          sortable: false,
        },
        { text: 'Cost', value: 'cost' },
        { text: 'Status', value: 'status' },
        { text: 'Sent at', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ]

      if (this.selectedStation === 'all') {
        headers.splice(headers.length - 1, 0, { text: 'Station', value: 'station' })
      }

      return headers
    },
    resendSmsMessage() {
      return `Are you sure you want to resend this sms to ${this.toResendCustomerName}?`
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchSms()
      },
      deep: true,
    },
    selectedStation() {
      this.fetchSms()
    },
    searchColumn() {
      if (this.search) {
        this.fetchSms()
      }
    },
    activeSearchFilter() {
      if (this.search !== '') {
        this.fetchSms()
      }
    },
  },
  mounted() {
    if (this.forSpecificCustomer) {
      this.defaultDatePickerDate = 'Last 1 year'
    } else {
      this.defaultDatePickerDate = 'Last 30 days'
    }
    this.fetchSms()
  },
  methods: {
    fetchSms: _.debounce(function () {
      this.isSmsLoading = true

      const sortOrder = this.options.sortDesc[0] ? 'desc' : 'asc'

      const params = {
        fromDate: this.filters.dates.fromDate,
        toDate: this.filters.dates.toDate,
        sortBy: this.options.sortBy[0],
        sortDirection: sortOrder,
        search: this.search,
        delivered: this.filters.smsStatus.delivered,
        sent: this.filters.smsStatus.sent,
        failed: this.filters.smsStatus.failed,
        searchColumn: this.activeSearchFilter,
        page: this.options.page,
        perPage: this.options.itemsPerPage,
      }

      axios
        .get(this.smsUrl, {
          params,
        })
        .then(response => {
          const responseData = response.data.sms
          this.smsCost = response.data.sms_cost
          this.sms = responseData.data
          this.options.page = responseData.current_page
          this.options.pageCount = responseData.last_page
          this.options.itemsPerPage = responseData.per_page
          this.pagination.totalItems = responseData.total
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
        .finally(() => {
          this.isSmsLoading = false
          this.isSmsRefreshing = false
        })
    }, 500),
    resendSms() {
      this.isConfirmResendDialogButtonLoading = true
      axios
        .post(`resend/sms/${this.toResend}`)
        .then(() => {
          this.$toast.success(`Sms has been resend to ${this.toResendCustomerName}`)
          this.isConfirmResendDialogButtonLoading = false
          this.showConfirmResendDialog = false
          this.onSmsSent()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
          this.isConfirmResendDialogButtonLoading = false
          this.showConfirmResendDialog = false
        })
    },
    deleteSelectedSms() {
      this.isConfirmBulkDeleteDialogButtonLoading = true
      const selectedIds = this.selected.map(sms => sms.id)

      axios
        .delete('bulk-delete/sms', { data: { ids: selectedIds } })
        .then(() => {
          this.selected = []
          this.fetchSms()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.showConfirmBulkDeleteDialog = false
          this.isConfirmBulkDeleteDialogButtonLoading = false
        })
    },
    deleteSms() {
      this.showConfirmDeleteDialog = false
      const index = this.sms.findIndex(
        item => item.id === this.toDelete,
      )
      this.sms.splice(index, 1)
      axios
        .delete(`sms/${this.toDelete}`)
        .then()
    },
    showResendSmsDialog(sms) {
      this.showConfirmResendDialog = true
      this.toResend = sms.id
      this.toResendCustomerName = sms.phone
    },
    showDeleteSmsDialog(id) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
    },
    refreshSms() {
      this.isSmsRefreshing = true
      this.fetchSms()
    },
    onSearchFilterChange(filter) {
      this.activeSearchFilter = filter
    },
    showMessage(message) {
      this.message = message
      this.showMessageDialog = true
    },
    onSmsSent() {
      this.fetchSms()
      this.$emit('sms-sent')
      this.$emit('close')
    },
    applyFilters(filters) {
      this.filters = filters
      this.fetchSms()
    },
  },
}
</script>

<style scoped>
.text-truncate {
  max-width: 300px; overflow: hidden; white-space: nowrap;
}
.clickable {
  cursor: pointer;
}
.v-chip.failed {
  background: #f44336;
}
.v-chip.delivered {
  background: #4caf50;
}
.v-chip.queued,
.v-chip.insufficient-balance {
  background: #ffa726;
}
.v-chip.sent {
  background: #2196f3;
}
</style>
