<template>
  <v-card :min-height="210">
    <v-card-title class="mb-0 pb-1">
      Personal Details
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-container class="mt-0">
        <v-row>
          <v-col
            v-if="!(user || customer) &&!isLoading"
            cols="12"
          >
            <h3 class="font-weight-light">
              Customer not found
            </h3>
          </v-col>
          <v-col
            v-for="detail in details"
            :key="detail.label"
            cols="12"
            md="3"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="user || customer"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                {{ detail.label }}
              </h4>
              <h4 class="font-weight-medium">
                <span v-if="isLoading">
                  <details-text-shimmer />
                </span>
                <span v-else>
                  <span v-if="detail.label === 'Registration date'">{{ detail.value | formatDate }}</span>
                  <span v-else-if="detail.label === 'Total debt' || detail.label === 'Total credit'">Ksh {{ detail.value | formatCurrency }}</span>
                  <span v-else>{{ detail.value }}</span>
                </span>
              </h4>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import DetailsTextShimmer from '@/components/partials/shimmers/DetailsTextShimmer.vue'

export default {
  components: {
    'details-text-shimmer': DetailsTextShimmer,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    customer: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    details() {
      const details = [
        { label: 'Username', value: this.customer ? this.customer.user.username : this.user.username || 'N/A' },
        { label: 'Name', value: this.customer ? this.customer.user.name : this.user.name || 'N/A' },
        { label: 'Account Number', value: this.customer ? this.customer.account_number : this.user.customer.account_number || 'N/A' },
        { label: 'Email', value: this.customer ? this.customer.user.email : this.user.email || 'N/A' },
        { label: 'Phone Number', value: this.customer ? this.customer.user.phone_number : this.user.phone_number || 'N/A' },
        { label: 'Address', value: this.customer ? this.customer.address : this.user.customer.address || 'N/A' },
        { label: 'Total debt', value: this.customer ? this.customer.debt : this.user.customer.debt || 'N/A' },
        { label: 'Total credit', value: this.customer ? this.customer.credits : this.user.customer.credits || 'N/A' },
        { label: 'Ip Address', value: this.customer ? this.customer.ip_address : this.user.customer.ip_address || 'N/A' },
        { label: 'Mac Address', value: this.customer ? this.customer.mac_address : this.user.customer.mac_address || 'N/A' },
        { label: 'Registration date', value: this.customer ? this.customer.created_at : this.user.created_at || 'N/A' },
      ]

      return details
    },
  },
}
</script>
