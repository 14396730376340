<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="text-center">
          <div><strong>Download (Tx):</strong> {{ txSpeedTextValue }}</div>
          <div><strong>Upload (Rx):</strong> {{ rxSpeedTextValue }}</div>
        </div>
        <apexchart
          type="line"
          :height="height"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    height: {
      type: Number,
      default: 300,
    },
    url: {
      type: String,
      default: null,
      required: true,
    },
    interfaceName: {
      type: String,
      default: null,
    },
  },
  data() {
    // Initialize an array for the first 6 data points with timestamps
    const initialDataTx = []
    const initialDataRx = []
    const currentTime = new Date().getTime()
    const interval = 2000 // 2 seconds interval as per your fetchData interval

    for (let i = 5; i >= 0; i--) {
      const time = currentTime - i * interval
      initialDataTx.push({ x: time, y: 0 })
      initialDataRx.push({ x: time, y: 0 })
    }

    return {
      txSpeedTextValue: '0 bps',
      rxSpeedTextValue: '0 bps',
      chartOptions: {
        chart: {
          type: 'line',
          height: 200,
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 2000,
            },
          },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          range: 10000,
          labels: {
            formatter(value, timestamp) {
              return new Date(timestamp).toLocaleTimeString()
            },
          },
        },
        yaxis: [
          {
            labels: {
              formatter: val => this.formatSpeed(val)
              ,
            },
          },
        ],
        tooltip: {
          x: {
            formatter(value) {
              return new Date(value).toLocaleTimeString()
            },
          },
        },
      },
      series: [
        { name: 'Download (Tx)', data: initialDataTx },
        { name: 'Upload (Rx)', data: initialDataRx },
      ],
      consecutiveFailures: 0,
      pendingRequests: 0,
      lastTxSpeed: 0,
      lastRxSpeed: 0,
    }
  },
  mounted() {
    this.checkPageVisibility()

    // Listen for changes in page visibility
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
    clearInterval(this.chartUpdateId)

    // Remove the visibility change event listener
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },
  methods: {
    async fetchData() {
      if (!this.url) {
        console.warn('Invalid url. Skipping fetchData.')

        return
      }
      if (this.pendingRequests >= 3) {
        console.warn('Max pending requests reached. Skipping fetchData.')

        return
      }
      this.pendingRequests++
      try {
        const customerData = await this.fetchDataFromAPI()
        if (customerData) {
          this.consecutiveFailures = 0
          this.lastTxSpeed = customerData['tx-bits-per-second']
          this.lastRxSpeed = customerData['rx-bits-per-second']

          this.txSpeedTextValue = this.lastTxSpeed ? this.formatSpeed(this.lastTxSpeed) : 'N/A'
          this.rxSpeedTextValue = this.lastRxSpeed ? this.formatSpeed(this.lastRxSpeed) : 'N/A'
        } else {
          this.consecutiveFailures++
          if (this.consecutiveFailures >= 3) {
            // Stop the interval after 3 consecutive failures
            clearInterval(this.intervalId)
            clearInterval(this.chartUpdateId)
          }
        }

        // ... (unchanged)
      } catch (error) {
        console.error('An error occurred while fetching data:', error)
      } finally {
        // Decrease the count of pending requests, ensuring it doesn't go below zero
        this.pendingRequests = Math.max(0, this.pendingRequests - 1)
      }
    },
    updateChartData() {
      // Use the last fetched data for updating the chart
      this.addChartData(this.lastTxSpeed, this.lastRxSpeed)
    },

    addChartData(txSpeed, rxSpeed) {
      // Get the current time in Unix Timestamp format (in milliseconds)
      const currentTime = new Date().getTime()

      // Add the new data points
      this.series[0].data.push({ x: currentTime, y: txSpeed || 0 })
      this.series[1].data.push({ x: currentTime, y: rxSpeed || 0 })

      // Force update the series (this will trigger chart re-render)
      this.series = [...this.series]
    },
    async fetchDataFromAPI() {
      try {
        const response = await axios.get(this.url)
        const data = this.interfaceName ? response.data[this.interfaceName][0] : response.data[0]

        return data
      } catch (error) {
        console.error('An error occurred while fetching data:', error)

        return null
      }
    },
    formatSpeed(bps) {
      const unitDivisors = [1, 1e3, 1e6, 1e9]
      const unitLabels = ['bps', 'Kbps', 'Mbps', 'Gbps']

      for (let i = unitDivisors.length - 1; i >= 0; i--) {
        if (bps >= unitDivisors[i]) {
          return `${(bps / unitDivisors[i]).toFixed(2)} ${unitLabels[i]}`
        }
      }

      return '0 bps'
    },
    checkPageVisibility() {
      // Check page visibility and start/stop interval accordingly
      if (document.visibilityState === 'visible' && !this.intervalId) {
        this.startDataFetchingInterval()
      } else if (document.visibilityState === 'hidden' && this.intervalId) {
        clearInterval(this.intervalId)
        clearInterval(this.chartUpdateId)
        this.intervalId = null
        this.chartUpdateId = null
      }
    },
    startDataFetchingInterval() {
      // Set up intervals for both fetchData and consistent graph update
      this.intervalId = setInterval(() => {
        this.fetchData()
      }, 2000)

      this.chartUpdateId = setInterval(() => {
        this.updateChartData()
      }, 2000)
    },
    handleVisibilityChange() {
      // Check page visibility and start/stop interval accordingly
      this.checkPageVisibility()
    },
  },
}
</script>
